<template>
  <div>
    <h2>Dashboard</h2>
    <p>Welcome to the dashboard!</p>
  </div>
</template>

<script>
import axios from '@/axios'; // Import the custom Axios instance

export default {
  name: 'UserDashboard',
  async created() {
    try {
      const response = await axios.get('/dashboard');
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching dashboard data', error);
    }
  }
};
</script>

